<template>
  <div>
    <div class="instagram" :class="containerClass">
      <div class="col-xs-12 col-vbspace">
        <p class="title tblack bold">Nos derniers posts instagram</p>
        <p>@widddi3d</p>
      </div>

      <div class="instagram__wrapper">
        <div
          v-for="(feed, index) in feeds"
          :key="index"
          class="instagram__box"
        >
          <figure class="instagram__figure">
            <img class="instagram__image" :src="feed.media_url" alt="" />
          </figure>
        </div>
      </div>

      <div class="fancy-alert">{{ error.error_message }}</div>
    </div>

    <div class="col-big-vspace" v-show="subscribe">
      <a
        href="https://www.instagram.com/widddi3d/"
        target="_blank"
        class="btn btn-classic"
      >
        Abonnez-vous !
      </a>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { mapGetters } from 'vuex';
import { errorActions } from '../../mixins';

export default {
  mixins: [errorActions],

  props: {
    count: {
      type: Number,
      default: 3,
    },

    tags: {
      type: Array,
      default: () => [],
    },

    containerClass: {
      type: String,
      default: '',
    },

    subscribe: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      error: '',
      feeds: [],
    };
  },

  computed: {
    ...mapGetters({
      baseurl: 'app/baseurl',
    }),
  },

  mounted() {
    this.getUserFeed();
  },

  methods: {
    async getUserFeed() {
      const { data } = await axios.get(`${this.baseurl}/instagram/feed`);
      const { feed } = data;
      const types = ['IMAGE', 'CAROUSEL_ALBUM'];

      this.feeds = feed
        .filter(media => {
          media.tags = media.caption.split('#');
          return (
            types.includes(media.media_type) &&
            this.tags.some(tag => media.tags.includes(tag))
          );
        })
        .slice(0, 6);
    },
  },
};
</script>
