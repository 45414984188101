<template>
  <TemplateBasic cloud instagram :tags="tags">
    <template v-slot:cloud>
      <Carousel
        :scrollPerPage="false"
        :perPage="itemsToShow"
        :navigationEnabled="true"
        :paginationEnabled="false"
        :loop="true"
        :autoplay="env !== 'dev'"
        :autoplayTimeout="3500"
        :speed="1500"
        :easing="'ease-out'"
        navigationNextLabel="<i class='fa fa-chevron-right'></i>"
        navigationPrevLabel="<i class='fa fa-chevron-left'></i>"
        :key="key"
      >
        <Slide
          class="carousel"
          v-for="(slide, slideIndex) in slides"
          :key="slideIndex"
        >
          <img width="100%" :src="slide.src" :alt="slide.name" />
          <div class="carousel__hover">
            <div class="carousel__content">
              <p class="carousel__title">{{ slide.name }}</p>

              <router-link
                :to="slide.link"
                tag="a"
                class="btn btn-border-white"
              >
                Personnaliser
              </router-link>
            </div>
          </div>
        </Slide>
      </Carousel>
    </template>

    <template v-slot:body>
      <WidddiProductList :articles="articles" />

      <section class="wrap col-big-vspace">
        <p class="sub-title tblack bold col-vbspace">Qui sommes-nous ?</p>

        <p class="col-vbspace">
          Widddi vous propose des
          <strong>objets personnalisés imprimés en 3D</strong> : Cadres de
          naissance, cadres de baptême, cadres photo à suspendre,
          décorations infini pour couples, prénoms 3D,...
        </p>

        <p class="col-vbspace">
          Chaque commande est modélisée (en 2D puis en 3D), imprimée à la
          pièce, finalisée à la main et expédiée depuis notre petite maison
          à côté de
          <strong>Lille</strong>. Moi à la créa et fabrication et lui aux
          commandes du site internet Widddi.com.
        </p>

        <p class="col-vbspace">
          <strong>Matière</strong> : Je n’utilise que du PLA, un polymère
          créé à partir d’amidon de maïs qui est entièrement recyclable et
          biodégradable. 🌿 Le <strong>PLA</strong> est une matière très
          légère (ca ne pèse pas très lourd, cela peut surprendre 😆) mais
          elle permet un grand nombre de possibilités créatives.
        </p>

        <p class="col-vbspace">
          Nous expédions en <strong>France</strong> métropolitaine et en
          <strong>Belgique</strong>, , via La Poste, Bpost ou Mondial
          Relay.
          <strong
            >La livraison Mondial Relay est offerte dès 60&euro;
            d'achats</strong
          >
          (hors frais de livraison).
        </p>

        <WidddiSocials />
      </section>

      <WidddiInstagram
        :count="6"
        :tags="tags"
        containerClass="row"
      ></WidddiInstagram>
    </template>
  </TemplateBasic>
</template>

<script>
import { mapGetters } from 'vuex';
import { Carousel, Slide } from 'vue-carousel';
import { TemplateBasic, WidddiProductList } from '@/components/template';
import { WidddiSocials } from '@/components/template/Elements';
import { WidddiInstagram } from '@/components/external';

export default {
  created() {
    window.addEventListener('resize', this.getWindowWidth);
    this.getWindowWidth();
  },

  mounted() {
    if (localStorage.getItem('cvd-20-1') !== null) {
      localStorage.removeItem('cvd-20-1');
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },

  data() {
    return {
      tags: ['widddi3d'],
      key: 0,
      itemsToShow: 3,
      slides: [
        {
          src: require('../../public/img/rainbow/cadre-naissance-personnalise-arc-en-ciel.jpg'),
          name: 'Cadre de naissance Arc-en-ciel',
          link:
            '/produit/cadre-de-naissance/cadre-de-naissance-arc-en-ciel',
        },
        {
          src: require('../../public/img/tipi/cadre-tipi-1.jpg'),
          name: 'Cadre photo Tipi',
          link: '/produit/cadre-a-suspendre/cadre-photo-tipi',
        },
        {
          src: require('../../public/img/cadre-perso-horizontal/cadre-perso-horizontal-31.jpg'),
          name: 'Cadres photo personnalisés',
          link: '/produit/cadre-photo-personnalise',
        },
        {
          src: require('../../public/img/horizontal/cadre-horizontal-22.jpg'),
          name: 'Cadres de naissance personnalisés',
          link: '/produit/cadre-de-naissance',
        },
        {
          src: require('../../public/img/bapteme/cadre-bapteme-personnalise-15.jpg'),
          name: 'Cadre de baptême',
          link: '/produit/cadre-de-bapteme',
        },
        {
          src: require('../../public/img/prenoms/prenom-12.jpg'),
          name: 'Prénom 3d',
          link: '/produit/prenoms-3d',
        },
        {
          src: require('../../public/img/infinite/infini-15.jpg'),
          name: 'Décoration infini',
          link: '/produit/decorations/infini',
        },
        {
          src: require('../../public/img/christmas/boule-nuit-etoilee-1.jpeg'),
          name: 'Boule de Noël "Nuit Étoilée"',
          link: '/produit/noel/decorations-de-noel/nuit-etoilee',
        },
      ],
      articles: [
        {
          src: require('../../public/img/materiel-creatif/creativo/set-tampons-alphabet-creativo-complet.jpg'),
          variant: 'Matériel créatif',
          link: '/produit/materiel-creatif',
        },
        {
          src: require('../../public/img/vertical/cadre-vertical-52.jpg'),
          variant: 'Cadres de naissance personnalisés',
          link: '/produit/cadre-de-naissance',
        },
        {
          src: require('../../public/img/bapteme/cadre-bapteme-personnalise-15.jpg'),
          variant: 'Cadre de baptême',
          link: '/produit/cadre-de-bapteme',
        },
        {
          src: require('../../public/img/rainbow/cadre-naissance-personnalise-arc-en-ciel.jpg'),
          variant: 'Cadre de naissance Arc-en-ciel',
          link:
            '/produit/cadre-de-naissance/cadre-de-naissance-arc-en-ciel',
        },
        {
          src: require('../../public/img/prenoms/prenom-3.jpg'),
          variant: 'Prénoms 3d personnalisés',
          link: '/produit/prenoms-3d',
        },
        {
          src: require('../../public/img/cadeau-parrain-marraine/cadeau-demande-marraine-cadre-photo.jpg'),
          variant: 'Cadeaux de demande parrain/marraine',
          link: '/produit/cadeau-de-demande-parrain-marraine',
        },
        {
          src: require('../../public/img/cadre-perso-horizontal/cadre-perso-horizontal-31.jpg'),
          variant: 'Cadre personnalisé à poser',
          link: '/produit/cadre-photo-personnalise',
        },
        {
          src: require('../../public/img/infinite/infini-1.jpg'),
          variant: 'Décorations personnalisées',
          link: '/produit/decorations',
        },
        {
          src: require('../../public/img/mini-cadres/enseigner-1.jpg'),
          variant: 'Cadeau de remerciement',
          link: '/produit/petit-cadre/cadeau-de-remerciement',
        },
        {
          src: require('../../public/img/hanging-horizontal/cadre-horizontal-suspendu-10.jpg'),
          variant: 'Cadres à suspendre',
          link: '/produit/cadre-a-suspendre',
        },
        {
          src: require('../../public/img/gift/carte-cadeau-widddi.jpg'),
          variant: 'Cartes cadeaux',
          link: '/produit/cadeaux/cartes-cadeaux',
        },
        {
          src: require('../../public/img/christmas/boule-perso-8.jpg'),
          variant: 'Décorations de Noël',
          link: '/produit/noel/decorations-de-noel',
        },
      ],
    };
  },

  components: {
    TemplateBasic,
    Carousel,
    Slide,
    WidddiInstagram,
    WidddiSocials,
    WidddiProductList,
  },

  computed: {
    ...mapGetters({
      cart: 'shop/cart',
      auth: 'app/auth',
      env: 'app/env',
      baseurl: 'app/baseurl',
      priceCadre: 'shop/priceCadre',
    }),
  },

  methods: {
    getWindowWidth() {
      let toShow = this.itemsToShow;
      if (window.innerWidth < 410) {
        toShow = 1;
      } else if (window.innerWidth < 768) {
        toShow = 2;
      } else if (window.innerWidth < 1100) {
        toShow = 3;
      } else {
        toShow = 4;
      }

      if (toShow !== this.itemsToShow) {
        this.itemsToShow = toShow;
        this.$nextTick(() => {
          this.key += 1;
        });
      }
    },
  },
};
</script>
